import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { hasError, isReady } from 'dmpconnectjsapp-base/helpers/common';
import { extractDate } from 'dmpconnectjsapp-base/helpers/accessors';
import {
  insTypes, insTypeToOid, sexes, shortSexes,
} from '../../constants/dmpConstants';
import InputDate from '../../../components/Common/Form/Input/InputDate';
import TitleTooltip from '../../../components/Common/TitleTooltip';

export const resultToText = (result) => {
  let text = '';
  // text += `Civilité\n${Object.entries(civility).map(([key, value]) => `${key}\n${value}\n\n`)}`;
  // text += `INS\n${Object.entries(insValues).map(([key, value]) => `${key}\n${value}\n\n`)}`;
  if (result.i_insIdentityResult === 1) {
    text += 'codeCR\n01\n'
      + 'LibelleCR\nAucune identité trouvée\n';
  } else if (result.i_insIdentityResult === 3) {
    text += 'codeCR\n02\n'
      + 'LibelleCR\nPlusieurs identités trouvées\n';
  } else {
    text += 'codeCR\n00\n'
        + 'LibelleCR\nOK\n'
        + `Nom\n${result.Identity.s_birthName}\n`
        + `Prénom(s)\n${result.Identity.s_birthGiven}\n`
        + `Sexe\n${shortSexes[result.Identity.i_sex]}\n`
        + `Date de naissance\n${result.Identity.s_birthDate && extractDate(result.Identity.s_birthDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}\n`
        + `Lieu de naissance\n${result.Identity.s_birthPlace}\n`

        + `Ins\n${result.Identity.Ins.s_value}\n`
        + `Clé\n${result.Identity.Ins.s_key}\n`
        + `OID\n${result.Identity.Ins.s_oid}\n`
        + `Début de validité\n${result.Identity.Ins.s_startDate && extractDate(result.Identity.Ins.s_startDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}\n`
        + `Fin de validité\n${result.Identity.Ins.s_endDate && extractDate(result.Identity.Ins.s_endDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}\n`;

    if (result.Identity.History) {
      text += 'Historique\n';
      text += result.Identity.History.map(history => `Ins\n${history.s_value}\n`
        + `Clé\n${history.s_key}\n`
        + `Début de validité\n${history.s_startDate && extractDate(history.s_startDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}\n`
        + `Fin de validité\n${history.s_endDate && extractDate(history.s_endDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}\n`);
    }
  }

  return text;
};

export const resultToJS = (result) => {
  let js = {
    exportId: result.exportId || undefined,
  };
  if (isReady(result)) {
    // aucun ins trouvé
    if (result.i_insIdentityResult === 1) {
      js = {
        ...js,
        CR: {
          codeCR: '01',
          LibelleCR: 'Aucune identité trouvée',
        },
      };
    } else if (result.i_insIdentityResult === 3) {
      js = {
        ...js,
        CR: {
          codeCR: '02',
          LibelleCR: 'Plusieurs identités trouvées',
        },
      };
    } else {
      js = {
        ...js,
        CR: {
          codeCR: '00',
          LibelleCR: 'OK',
        },
        Ins: {
          nir: result.Identity.Ins.s_value,
          key: result.Identity.Ins.s_key,
          startDate: result.Identity.Ins.s_startDate,
          endDate: result.Identity.Ins.s_endDate,
          oid: result.Identity.Ins.s_oid,
        },
        name: result.Identity.s_birthName,
        given: result.Identity.s_given,
        birthGiven: result.Identity.s_birthGiven,
        sex: shortSexes[result.Identity.i_sex],
        birthDate: result.Identity.s_birthDate,
        birthPlace: result.Identity.s_birthPlace,
      };


      if (result.Identity.History) {
        js = {
          ...js,
          History: result.Identity.History.map(history => ({
            nir: history.s_value,
            key: history.s_key,
            startDate: history.s_startDate,
            endDate: history.s_endDate,
            oid: history.s_oid,
          })),
        };
      }
    }
  } else if (hasError(result)) {
    js = {
      ...js,
      error: result.error.s_apiErrorExtendedInformations,
    };
  }

  if (result.params && result.params.search) {
    js = { ...js, request: result.params.search };
  }
  if (result.params && result.params.vitaleData) {
    js = { ...js, vitaleData: result.params.vitaleData };
  }

  return js;
};

export const resultToJson = (result) => {
  const js = resultToJS(result);

  return JSON.stringify(js);
};

export const resultToHtml = result => (
  <>
    <div id="insi_result_civ">
      <h6>Identité Nationale de Santé</h6>
      <Form>
        <Row>
          <Col sm={6}>
            <Form.Group>
              <Form.Label>Nom de naissance</Form.Label>
              <Form.Control
                size="sm"
                readOnly
                value={result.Identity.s_birthName}
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group>
              <Form.Label>Liste des prénoms de naissance</Form.Label>
              <Form.Control
                size="sm"
                readOnly
                value={result.Identity.s_birthGiven}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group>
              <Form.Label>Premier prénom de naissance</Form.Label>
              <Form.Control
                size="sm"
                readOnly
                value={result.Identity.s_given}
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group>
              <Form.Label>Sexe</Form.Label>
              <Form.Control
                size="sm"
                value={sexes.find(sex => sex.value === result.Identity.i_sex).label}
                readOnly
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group>
              <Form.Label>Date de naissance</Form.Label>
              <InputDate
                size="sm"
                value={extractDate(result.Identity.s_birthDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                readOnly
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group>
              <Form.Label>
                Code lieu de naissance (code INSEE)
                <TitleTooltip
                  text="Code Officiel Géographique de l'INSEE"
                  id="cog_insee"
                >
                  <i className="la la-question-circle" />
                </TitleTooltip>
              </Form.Label>
              <Form.Control
                size="sm"
                readOnly
                value={result.Identity.s_birthPlace}
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </div>

    <div id="insi_result_ins">
      <Form>
        <Row>
          <Col sm={6}>
            <Form.Group>
              <Form.Label>Matricule INS</Form.Label>
              <Form.Control
                size="sm"
                readOnly
                value={`${result.Identity.Ins.s_value}${result.Identity.Ins.s_key}`}
                isInvalid={result.Identity.Ins.s_oid === insTypeToOid[insTypes.TEMP_INS]}
              />
              <Form.Control.Feedback type="invalid">Identifiant temporaire</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group>
              <Form.Label>Début de validité</Form.Label>
              <InputDate
                size="sm"
                value={
                result.Identity.Ins.s_startDate
                  ? extractDate(result.Identity.Ins.s_startDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
                  : ''
                }
                readOnly
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6} />
          <Col sm={6}>
            <Form.Group>
              <Form.Label>Fin de validité</Form.Label>
              <InputDate
                size="sm"
                value={
                  result.Identity.Ins.s_endDate
                    ? extractDate(result.Identity.Ins.s_endDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
                    : ''
                }
                readOnly
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </div>
    <div id="insi_result_historic">
      <h6>Historique</h6>
      <Form>
        {result.Identity.History && result.Identity.History.map(history => (
          <div key={`${history.s_value} + ${history.s_key}`} className="insi_result_historic_entry">
            <Row>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Matricule INS</Form.Label>
                  <Form.Control
                    size="sm"
                    readOnly
                    value={`${history.s_value}${history.s_key}`}
                    isInvalid={history.s_oid === insTypeToOid[insTypes.TEMP_INS]}
                  />
                  <Form.Control.Feedback type="invalid">Identifiant temporaire</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Début de validité</Form.Label>
                  <InputDate
                    size="sm"
                    value={history.s_startDate ? extractDate(history.s_startDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6} />
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Fin de validité</Form.Label>
                  <InputDate
                    size="sm"
                    value={history.s_endDate ? extractDate(history.s_endDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        ))}
      </Form>
    </div>
  </>
);
